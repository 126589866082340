import * as React from "react"

const NetlifyForms: React.FunctionComponent = () => {
  return (
    <div style={{ maxWidth: "600px", margin: "125px auto" }}>
      {/* <!-- NEWSLETTER SIGN UP FORM--> */}
      <form name='Newsletter' method='POST' data-netlify='true' data-netlify-honeypot='bot-field'>
        <input type='hidden' name='form-name' value='Newsletter' />

        <p hidden>
          <label id='Newsletter-bot-label'>
            Don't fill this out if you're human: <input name='bot-field' aria-labelledby='Newsletter-bot-label' />
          </label>
        </p>

        <div className='c-lrg-form__field'>
          <label className='c-lrg-form__label' htmlFor='newsletter_email'>
            Enter your email*
          </label>
          <input type='email' id='newsletter_email' name='Newsletter Email' className='c-lrg-form__input' required />
        </div>

        <div className='c-newsletter__actions'>
          <button className='o-circ-btn' type='submit'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 113.39 113.39'>
              <path
                d='M104.5,58.68,72.89,83.5l2.7,3,37.8-29.93L75.59,26.84l-2.71,3L104.5,54.7H0v4Z'
                fill='rgba(250, 250, 250, 1)'
                fillRule='evenodd'
              />
              <rect width='113.39' height='113.39' fill='none' opacity='0' />
            </svg>
          </button>
          <button className='o-circ-btn' type='button'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 113.39 113.39'>
              <path
                d='M32.31,34l3.33-3.33L58.35,53.27,81.07,30.65,84.39,34,61.68,56.69,84.39,79.41l-3.33,3.32L58.35,60.12,35.64,82.73l-3.33-3.32L55,56.69Z'
                fill='rgba(250, 250, 250, 1)'
              />
              <rect width='113.39' height='113.39' fill='none' opacity='0' />
            </svg>
          </button>
        </div>
      </form>

      {/* <!-- CONTACT ENQUIRY FORM--> */}
      <form name='Contact Enquiry' method='POST' data-netlify='true' data-netlify-honeypot='bot-field'>
        <input type='hidden' name='form-name' value='Contact Enquiry' />

        <p hidden>
          <label id='Contact Enquiry-bot-label'>
            Don't fill this out if you're human: <input name='bot-field' aria-labelledby='Contact Enquiry-bot-label' />
          </label>
        </p>

        <fieldset>
          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='contact-name'>
              Your name*
            </label>
            <input type='text' id='contact-name' name='Contact Name' className='c-lrg-form__input' required />
          </div>

          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='contact-phone-number'>
              Your phone number*
            </label>
            <input type='tel' id='contact-phone-number' name='Contact Phone Number' className='c-lrg-form__input' required />
          </div>

          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='contact-email'>
              Your email*
            </label>
            <input type='email' id='contact-email' name='Contact Email' className='c-lrg-form__input' required />
          </div>

          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='contact-message'>
              Your message*
            </label>
            <textarea id='contact-message' name='Contact Message' className='c-lrg-form__input' required />
          </div>

          <div className='c-lrg-form__field is-gdpr'>
            <div className='o-checkbox'>
              <input type='checkbox' id='contact-gdpr' name='Contact GDPR' value='true' required />
              <div className='o-checkmark'></div>
            </div>

            <p className='o-par-sml'>
              I agree to the
              <a href='/terms' className='o-inline-link is-bodylink' target='_blank' rel='noopener noreferrer'>
                privacy policy
              </a>
              & consent to my details being collected.
            </p>
          </div>

          <button type='submit'>submit</button>
        </fieldset>
      </form>

      {/* <!--REQUEST A PROPOSAL FORM--> */}
      <form name='Request a proposal' method='POST' data-netlify='true' data-netlify-honeypot='bot-field'>
        <input type='hidden' name='form-name' value='Request a proposal' />

        <p hidden>
          <label id='Request a proposal-bot-label'>
            Don't fill this out if you're human: <input name='bot-field' aria-labelledby='Request a proposal-bot-label' />
          </label>
        </p>

        <fieldset>
          <input type='radio' id='complete-website' value='Complete website' name='1. Proposal Scope' />
          <input type='radio' id='ui-ux-design' value='UI/UX Design' name='1. Proposal Scope' />
          <input type='radio' id='branding' value='Branding' name='1. Proposal Scope' />
          <input type='radio' id='development' value='Development' name='1. Proposal Scope' />
          <input type='radio' id='brand-and-website' value='Brand and Website' name='1. Proposal Scope' />
        </fieldset>

        <fieldset>
          <input type='radio' id='small-budget' value='£2,500 - £4,999' name='2. Proposal Budget' />
          <input type='radio' id='medium-budget' value='£5,000 - £7,499' name='2. Proposal Budget' />
          <input type='radio' id='large-budget' value='£7,500 - £10,000' name='2. Proposal Budget' />
          <input type='radio' id='xlarge-budget' value='£10,000+' name='2. Proposal Budget' />
        </fieldset>

        <fieldset>
          <input type='radio' id='a-project-of-ours' value='A project of ours' name='3. Proposal Referral' />
          <input type='radio' id='business-referral' value='Business referral' name='3. Proposal Referral' />
          <input type='radio' id='advert' value='An advert' name='3. Proposal Referral' />
          <input type='radio' id='search-engine' value='Search engine' name='3. Proposal Referral' />
          <input type='radio' id='word-of-mouth' value='Word of mouth' name='3. Proposal Referral' />
        </fieldset>

        <fieldset>
          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='sap-Proposal-first-name'>
              First name*
            </label>

            <input
              type='text'
              id='sap-Proposal-first-name'
              name='5_a. Proposal First Name'
              className='c-lrg-form__input'
              autoComplete='off'
              required
            />
          </div>

          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='sap-Proposal-last-name'>
              Last name*
            </label>

            <input
              type='text'
              id='sap-Proposal-last-name'
              name='5_b. Proposal Last Name'
              className='c-lrg-form__input'
              autoComplete='off'
              required
            />
          </div>

          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='sap-Proposal-phone-number'>
              Phone number*
            </label>

            <input
              type='tel'
              id='sap-Proposal-phone-number'
              name='5_c. Proposal Phone Number'
              className='c-lrg-form__input'
              autoComplete='off'
              required
            />
          </div>

          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='sap-Proposal-email'>
              Email*
            </label>

            <input
              type='email'
              id='sap-Proposal-email'
              name='5_d. Proposal Email'
              className='c-lrg-form__input'
              autoComplete='off'
              required
            />
          </div>
          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='sap-Proposal-company'>
              Company
            </label>
            <input
              type='text'
              id='sap-Proposal-company'
              name='5_e. Proposal Company'
              className='c-lrg-form__input'
              autoComplete='off'
            />
          </div>
          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='sap-Proposal-deadline'>
              Deadline
            </label>

            <input
              type='date'
              id='sap-Proposal-deadline'
              name='5_f. Proposal Deadline'
              className='c-lrg-form__input'
              autoComplete='off'
            />
          </div>
          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='sap-Proposal-message'>
              Message*
            </label>

            <textarea
              id='sap-Proposal-message'
              name='5_g. Proposal Message'
              className='c-lrg-form__input'
              autoComplete='off'
              required
            />
          </div>
          <div className='c-lrg-form__field is-gdpr'>
            ' <input type='checkbox' id='sap-Proposal-gdpr' name='Proposal GDPR' value='true' required />'
            <p className='o-par-sml' style={{ fontStyle: "italic", opacity: "0.5", textAlign: "left" }}>
              I agree to the
              <a href='/terms' className='o-inline-link is-bodylink' target='_blank' rel='noopener noreferrer'>
                {" "}
                privacy policy{" "}
              </a>{" "}
              & consent to my details being collected.
            </p>
          </div>
        </fieldset>

        <button type='submit'>submit</button>
      </form>

      {/* <!--SCHEDULE A CALL FORM--> */}
      <form name='Schedule a call' method='POST' data-netlify='true' data-netlify-honeypot='bot-field'>
        <input type='hidden' name='form-name' value='Schedule a call' />

        <p hidden>
          <label id='Schedule a call-bot-label'>
            Don't fill this out if you're human: <input name='bot-field' aria-labelledby='Schedule a call-bot-label' />
          </label>
        </p>

        <fieldset>
          <input type='radio' id='complete-website' value='Complete website' name='1. Call Scope' />
          <input type='radio' id='ui-ux-design' value='UI/UX Design' name='1. Call Scope' />
          <input type='radio' id='branding' value='Branding' name='1. Call Scope' />
          <input type='radio' id='development' value='Development' name='1. Call Scope' />
          <input type='radio' id='brand-and-website' value='Brand and Website' name='1. Call Scope' />
        </fieldset>

        <fieldset>
          <input type='radio' id='small-budget' value='£2,500 - £4,999' name='2. Call Budget' />
          <input type='radio' id='medium-budget' value='£5,000 - £7,499' name='2. Call Budget' />
          <input type='radio' id='large-budget' value='£7,500 - £10,000' name='2. Call Budget' />
          <input type='radio' id='xlarge-budget' value='£10,000+' name='2. Call Budget' />
        </fieldset>

        <fieldset>
          <input type='radio' id='a-project-of-ours' value='A project of ours' name='3. Call Referral' />
          <input type='radio' id='business-referral' value='Business referral' name='3. Call Referral' />
          <input type='radio' id='advert' value='An advert' name='3. Call Referral' />
          <input type='radio' id='search-engine' value='Search engine' name='3. Call Referral' />
          <input type='radio' id='word-of-mouth' value='Word of mouth' name='3. Call Referral' />
        </fieldset>

        <fieldset>
          <div style={{ position: "relative" }}>
            <label htmlFor='call-time' id='call-time-label'>
              Time*
            </label>
            <input type='time' id='call-time' name='4_b. Call Time' className='c-sap-input is-schedule' />
          </div>

          <div style={{ position: "relative" }}>
            <label htmlFor='call-date' id='call-date-label'>
              Date*
            </label>
            <input type='date' id='call-date' name='4_a. Call Date' className='c-sap-input is-schedule' />
          </div>
        </fieldset>

        <fieldset>
          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='sap-Call-first-name'>
              First name*
            </label>
            <input
              type='text'
              id='sap-Call-first-name'
              name='5_a. Call First Name'
              className='c-lrg-form__input'
              autoComplete='off'
              required
            />
          </div>
          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='sap-Call-last-name'>
              Last name*
            </label>

            <input
              type='text'
              id='sap-Call-last-name'
              name='5_b. Call Last Name'
              className='c-lrg-form__input'
              autoComplete='off'
              required
            />
          </div>
          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='sap-Call-phone-number'>
              Phone number*
            </label>

            <input
              type='tel'
              id='sap-Call-phone-number'
              name='5_c. Call Phone Number'
              className='c-lrg-form__input'
              autoComplete='off'
              required
            />
          </div>
          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='sap-Call-email'>
              Email*
            </label>

            <input type='email' id='sap-Call-email' name='5_d. Call Email' className='c-lrg-form__input' autoComplete='off' required />
          </div>
          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='sap-Call-company'>
              Company
            </label>
            <input type='text' id='sap-Call-company' name='5_e. Call Company' className='c-lrg-form__input' autoComplete='off' />
          </div>
          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='sap-Call-deadline'>
              Deadline
            </label>

            <input type='date' id='sap-Call-deadline' name='5_f. Call Deadline' className='c-lrg-form__input' autoComplete='off' />
          </div>
          <div className='c-lrg-form__field'>
            <label className='c-lrg-form__label' htmlFor='sap-Call-message'>
              Message*
            </label>

            <textarea id='sap-Call-message' name='5_g. Call Message' className='c-lrg-form__input' autoComplete='off' required />
          </div>
          <div className='c-lrg-form__field is-gdpr'>
            ' <input type='checkbox' id='sap-Call-gdpr' name='Call GDPR' value='true' required />'
            <p className='o-par-sml' style={{ fontStyle: "italic", opacity: "0.5", textAlign: "left" }}>
              I agree to the
              <a href='/terms' className='o-inline-link is-bodylink' target='_blank' rel='noopener noreferrer'>
                privacy policy
              </a>
              & consent to my details being collected.
            </p>
          </div>
        </fieldset>

        <button type='submit'>submit</button>
      </form>
    </div>
  )
}

export default NetlifyForms
